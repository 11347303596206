/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@font-face {
  font-family: Rajdhani-Bold;
  src: url('assets/fonts/Rajdhani-Bold.ttf');
}

@font-face {
  font-family: Roboto-Light;
  src: url('assets/fonts/Roboto-Light.ttf');
}

@font-face {
  font-family: Roboto-Medium;
  src: url('assets/fonts/Roboto-Medium.ttf');
}

p, span {
  margin: 0;
  padding: 0;
}

ion-searchbar {
  button {
    font-size: 1.8rem !important;
    top: -2px;
    position: relative;
    color: white;
  }
  input {
    color: white;
  }
}

.add-guest-modal {
  position: absolute;
  left: calc(50% - 185px);
  top: calc(50% - 265px);
  width: 370px;
  height: 530px;
  border-radius: 10px;
}

.add-schedule-modal {
  position: absolute;
  left: calc(50% - 186px);
  top: calc(50% - 265px);
  width: 370px;
  height: 600px;
  border-radius: 10px;
}

app-delete-modal {
  display: contents !important;
}

.delete-items-modal {
  position: absolute;
  width: 100%;
  max-width: 355px;
  height: 100px;
  border-radius: 10px;
}

.delete-expense-modal {
  max-width: 345px;
}

.delete-guest-modal {
  max-width: 370px;
}

ion-modal::part(content) {
    background: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

input[type=date]::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

input[type=datetime-local]::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

input[type=datetime-local]::-webkit-calendar-picker-indicator {
  color: #362B60;
}

input[type=file] {
  width: 100%;
  height: 100%;
  padding: 0;
  cursor: pointer;
}

input[type=time]::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

dp-date-picker {
  .dp-picker-input {
    font-family: Rajdhani-Bold, serif;
    display: inline-flex;
    height: 75px;
    width: 75%;
    max-width: 330px;
    border-radius: 10px;

    margin: 30px 10px 0;

    background: #362B60;
    color: white;
  }

  .dp-popup {
    .dp-day-calendar {
      .dp-day-calendar-container {
        background: #362B60 !important;
      }
    }
  }
}

body {
  margin: 0;
  --ion-background-color: linear-gradient(to right, #645599, #453B72 , #362B60 );

  height:100vh;
  text-align:center;
  color:#fff;
}
